import JwtService from '@/services/jwt.service'
import axios from '@/plugins/axios'

export default class DirectoryApiService {
  constructor() {
    this.setAuthHeader()
  }

  setAuthHeader() {
    const token = JwtService.getToken()
    axios.defaults.headers.common[import.meta.env.VITE_TOKEN_HEADER_KEY] = token ? `Bearer ${token}` : ''
  }

  async getOrgtypes() {
    const { data } = await axios.get('/v1/api_admin/directory/orgtypes')
    return data
  }

  async getPrograms() {
    const { data } = await axios.get('/v1/api_admin/directory/programs')
    return data
  }

  async getProgram(programId) {
    const { data } = await axios.get('/v1/api_admin/directory/programs/' + programId)
    return data
  }

  async saveProgram(params) {
    const { data } = await axios.post('/v1/api_admin/directory/programs/create', params)
    return data
  }

  async updateProgram(params) {
    const { data } = await axios.put('/v1/api_admin/directory/programs/update', params)
    return data
  }

  async getCity(cityId) {
    const { data } = await axios.get('/v1/api_admin/directory/cites/' + cityId)
    return data
  }

  async saveCity(params) {
    const { data } = await axios.post('/v1/api_admin/directory/cites/create', params)
    return data
  }

  async updateCity(params) {
    const { data } = await axios.put('/v1/api_admin/directory/cites/update', params)
    return data
  }
}
