import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'articles' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'articles' },
    meta: { authUser: true },
    children: [
      {
        name: 'articles',
        path: 'articles',
        component: () => import('../views/articles/Articles.vue'),
        children: [
          {
            name: 'createArticle',
            path: 'create_article',
            component: () => import('../views/articles/Article.vue'),
          },
          {
            name: 'editArticle',
            path: 'article/:articleId(.*)',
            props: true,
            component: () => import('../views/articles/Article.vue'),
          }
        ],
      },
      {
        name: 'institutions',
        path: 'institutions',
        component: () => import('../views/institutions/Institutions.vue'),
        children: [
          {
            name: 'createInstitution',
            path: 'create',
            component: () => import('../views/institutions/Institution.vue'),
          },
          {
            name: 'editInstitution',
            path: 'edit/:institutionId(.*)',
            props: true,
            component: () => import('../views/institutions/Institution.vue'),
          },
          {
            name: 'viewInstitution',
            path: 'view/:institutionId(.*)',
            props: true,
            component: () => import('../views/institutions/Institution_view.vue'),
          }
        ],
      },
      {
        name: 'workflow',
        path: 'workflow/',
        children: [
          {
            name: 'workflow_reviews',
            path: 'reviews_w',
            component: () => import('../views/workflow/Reviews.vue'),
          },
        ],
      },
      {
        name: 'directory',
        path: 'directory/',
        children: [
          {
            name: 'programs',
            path: 'programs',
            component: () => import('../views/directory/Programs.vue'),
            children: [
              {
                name: 'createProgram',
                path: 'create',
                component: () => import('../views/directory/Program.vue'),
              },
              {
                name: 'editProgram',
                path: 'edit/:programId(.*)',
                props: true,
                component: () => import('../views/directory/Program.vue'),
              },
            ],
          },
          {
            name: 'countries',
            path: 'countries',
            component: () => import('../views/directory/Countries.vue'),
            children: [
              {
                name: 'cites',
                path: ':countryId(.*)/cites',
                props: true,
                component: () => import('../views/directory/Cites.vue'),
              },
              {
                name: 'createCity',
                props: true,
                path: ':countryId(.*)/cites/create',
                component: () => import('../views/directory/City.vue'),
              },
              {
                name: 'editCity',
                path: ':countryId(.*)/cites/:cityId(.*)/edit',
                props: true,
                component: () => import('../views/directory/City.vue'),
              },
            ],
          }
        ],
      },
      {
        name: 'reviews',
        path: 'reviews/',
        component: () => import('../views/reviews/Reviews.vue'),
        children: [
          {
            name: 'reviews_view',
            path: 'view/:reviewId(.*)',
            props: true,
            component: () => import('../views/reviews/Review.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

export default function (store: any) {
  const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }
      // For some reason using documentation example doesn't scroll on page navigation.
      if (to.hash) {
        return { el: to.hash, behavior: 'smooth' }
      } else {
        window.scrollTo(0, 0)
      }
    },
    routes,
  })

  router.beforeEach(async (to, from, next) => {
    if (!store.userStore.loaded_data) {
      await store.userStore.auth()
    }

    if (to.meta.authUser !== undefined && to.meta.authUser && store.userStore.is_auth) {
      if (to.meta.newUser !== undefined && to.meta.newUser && store.userStore.user.status !== 'new') {
        next('/auth/login')
      }
    }
    if (to.meta.authUser !== undefined && to.meta.authUser && store.userStore.is_auth) {
    } else if (to.meta.authUser !== undefined && to.meta.authUser && !store.userStore.is_auth) {
      next('/auth/login')
    }
    // if (to.meta.env !== undefined && !to.meta.env.includes(import.meta.env.VITE_ENV))
    // {
    //     next('/')
    // }
    next()
  })

  return router
}

// export default router
